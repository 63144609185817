<template>
	<div class="page">
		<calc-page>
			<template slot="topBox">
				<round-container>
					<div class="item-box">
						<van-cell is-link>
							<div class="row-box" @click="sexShow = true">
								<title-item text="性别"></title-item>
								<span>{{ information.sex }}</span>
							</div>
						</van-cell>
					</div>
					<van-divider />

					<!-- <div class="item-box">
            <van-cell>
              <div class="row-box">
                <title-item text="年龄"></title-item>
                <van-field
                  readonly
                  clickable
                  :value="information.age"
                  @touchstart.native.stop="ageShow = true"
                />
                <span class="unit">岁</span>
              </div>
            </van-cell>
          </div>
          <van-divider /> -->
					<number-input-field name="年龄" unit="岁" :limit="2" v-model="information.age"></number-input-field>
					<year-month-number-input-field name="93年3月以前符合国家规定的连续工龄" v-model="information.monthNumAsFee"></year-month-number-input-field>
					<year-month-input-field name="首次工作时间" v-model="information.firstWorkTime"></year-month-input-field>

					<year-month-input-field name="预计退休时间" v-model="information.retireDate"></year-month-input-field>

					<!-- <div class="item-box">
            <van-cell>
              <div class="row-box">
                <title-item text="首次工作时间"></title-item>
                <year-month-input
                  class="date-input"
                  idx="0"
                  ref="firstWorkTime"
                ></year-month-input>
              </div>
            </van-cell>
          </div>
          <van-divider />

          <div class="item-box">
            <van-cell>
              <div class="row-box">
                <title-item text="预计退休时间"></title-item>
                <year-month-input
                  class="date-input"
                  idx="1"
                  ref="retireDate"
                ></year-month-input>
              </div>
            </van-cell>
          </div>
          <van-divider /> -->

					<number-input-field name="实际缴费年限" unit="年" :tip="true" @tip="onToast('payment')" v-model="information.paymentYearNum"></number-input-field>

					<div class="item-box">
						<van-cell is-link>
							<div class="row-box" @click="onAverageWage">
								<title-item text="平均缴费档次"></title-item>
								<span>{{ averageWage }}</span>
							</div>
						</van-cell>
					</div>
					<van-divider />

					<div class="bottom-text">
						该值为上年度全市职工月平均工资的0.6到3倍
					</div>
				</round-container>
			</template>

			<template slot="footerBtn">
				<div class="footer">
					<div class="btns-box">
						<div class="btn-box btn-bk" style="margin-right:10px" @click="onResult">粗略计算</div>
						<div class="btn-box btn-bk" @click="goAdvance">精确计算</div>
					</div>
				</div>
			</template>
		</calc-page>

		<van-action-sheet title="性别" v-model="sexShow" :actions="sexActions" @select="onSexSelect" />

		<van-action-sheet title="平均缴费工资指数" v-model="averageWageShow">
			<div class="action-box">
				<div v-for="(item, idx) in actions" :key="idx" v-bind:class="['action-item__box', clickItem == idx ? 'clickBk' : '']" @click="actionSelect(idx)">
					{{ item.name }}
				</div>
			</div>
		</van-action-sheet>
	</div>
</template>

<script>
import TitleItem from "@/views/calc/TitleItem";
import YearMonthInputField from "@/views/calc/components/YearMonthInputField";
import YearMonthNumberInputField from "@/views/calc/components/YearMonthNumberInputField";
import NumberInputField from "@/views/calc/components/NumberInputField";
import { Cell, CellGroup, Divider, Field, ActionSheet } from "vant";
import CalcPage from "@/components/CalcPage";
import { siCalc } from "@/api/tool";
import { mapMutations } from "vuex";
import RoundContainer from "@/views/calc/components/RoundContainer";

import to from "@/utils/to";
export default {
	components: {
		CalcPage,
		TitleItem,
		RoundContainer,
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Field.name]: Field,
		[ActionSheet.name]: ActionSheet,
		[Divider.name]: Divider,
		YearMonthInputField,
		YearMonthNumberInputField,
		NumberInputField,
	},
	data() {
		return {
			loading: false,
			information: {
				sex: "男",
				age: "",
				retireDate: "",
				monthNumAsFee: "0",
				firstWorkTime: "",
				paymentYearNum: "",
				averageWage: "",
				advanceds: [],
			},
			sexActions: [
				{ name: "男", value: "1" },
				{ name: "女", value: "2" },
			],
			show: false,
			sexShow: false,
			ageShow: false,
			firstWorkShow: false,
			retireDateShow: false,
			paymentYearNumShow: false,
			averageWageShow: false,

			averageWage: "",
			actions: [
				{ name: "60%", value: "0.6" },
				{ name: "100%", value: "1" },
				{ name: "150%", value: "1.5" },
				{ name: "200%", value: "2" },
				{ name: "250%", value: "2.5" },
				{ name: "300%", value: "3" },
			],
			clickItem: 0,
		};
	},
	methods: {
		onSexSelect(item) {
			this.information.sex = item.name;
			this.sexShow = false;
		},
		onAverageWage() {
			const idx = this.clickItem;
			this.averageWageShow = true;
			this.averageWage = this.actions[idx].name;
			this.information.averageWage = this.actions[idx].value;
		},
		actionSelect(idx) {
			this.clickItem = idx;
			this.averageWage = this.actions[idx].name;
			this.information.averageWage = this.actions[idx].value;
		},
		async onResult() {
			/* eslint-disable */
			let { sex, age, retireDate, monthNumAsFee, firstWorkTime, paymentYearNum, averageWage } = this.information;
      if (!this.validateParams()) {
				return;
			}
		  let data = {
				sex,
				age,
				monthNumAsFee,
				firstWorkTime: firstWorkTime + "/01",
				retireDate: retireDate + "/01",
				paymentYearNum,
				averageWage,
			};
      this.setAnnuityBasic(this.information);
      this.loading = true;
			let [err, res] = await to(siCalc(data));
			this.loading = false;

			if (err) {
				this.$toast.fail("计算失败");
				return;
			}
			this.$toast.success("计算成功");

			this.$router.push({
				name: "BasicAnnuityCalcResultPage",
				query: {
					isShow: true,
					res: JSON.stringify(res),
				},
			});
		},
		validateParams() {
			let { sex, age, retireDate, monthNumAsFee, firstWorkTime, paymentYearNum, averageWage } = this.information;

			if (age == null || age == "") {
				this.$toast.fail("请填写年龄");
				return false;
			}

			if (firstWorkTime == null || firstWorkTime == "") {
				this.$toast.fail("请填写首次参加工作时间");
				return false;
			}

			if (retireDate == null || retireDate == "") {
				this.$toast.fail("请填写预计退休时间");
				return false;
			}

			if (paymentYearNum == null || paymentYearNum == "") {
				this.$toast.fail("请填写实际缴费年限");
				return false;
			}

			if (averageWage == null || averageWage == "") {
				this.$toast.fail("请填写平均缴费档次");
				return false;
			}

			if (retireDate < firstWorkTime) {
				this.$toast.fail("退休时间小于首次参加工作时间，请重新输入");
				return false;
			}
			return true;
		},
		goAdvance() {
			if (!this.validateParams()) {
				return;
			}
      this.setAnnuityBasic(this.information);
			this.$router.push({ name: "BasicAnnuityCalcAdvancedPage" });
		},
		onToast(type) {
			if ("payment" === type) {
				this.$toast("如缴费未满15年，则按最低15年计算");
			}
		},
		...mapMutations("calc", ["setAnnuityBasic"]),
	},
};
</script>

<style scoped>
.date-input {
	margin-right: 4px;
}

.page >>> .van-divider {
	margin: 0;
}

.item-box {
	display: flex;
	align-items: flex-end;
	padding: 12px 0;
}

.item-box >>> .van-cell {
	padding: 0;
	font-size: 16px;
	display: flex;
	justify-content: space-between;
}

.row-box {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.row-box >>> .TitleItem__title--2DuKR {
	width: 168px;
}

.row-box .unit {
	font-size: 14px;
	font-weight: normal;
	position: relative;
	top: 1px;
}

.row-box >>> .van-cell::after {
	border: none;
}

.row-box >>> .van-field__control {
	text-align: right;
	font-size: 18px;
	font-weight: 600;
	color: #333;
}

.row-box span {
	font-size: 20px;
	font-weight: 600;
	color: #333;
}

.action-box {
	padding: 20px 6px 20px 36px;
	display: flex;
	flex-wrap: wrap;
}

.action-item__box {
	width: 88px;
	height: 38px;
	border: 1px solid #cccccc;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 20px 28px 0;
	border-radius: 8px;
	font-size: 20px;
	font-weight: bold;
	line-height: 28px;
	color: #333333;
}

.clickBk {
	background: #658CF1;
	color: #ffffff;
}

.bottom-text {
	font-size: 12px;
	line-height: 17px;
	color: #b3b3b3;
	margin: 20px 0 0 0px;
}


.btns-box {
	display: flex;
}

.btn-box {
	flex: 1;
	height: 50px;
	background: #cccccc;
	border-radius: 8px;
	font-size: 18px;
	font-weight: 500;
	line-height: 50px;
	color: #ffffff;
	text-align: center;
}

/* .icon-size {
  width: 18px;
  height: 18px;
  margin-top: 3px;
} */

.btn-bk {
	background: #658CF1;
}

.footer {
  width: 90%;
	position: fixed;
	bottom: 20px;
}
</style>
