<template>
  <input-field :name="name" :tip="tip">
    <year-month-number-input
      v-model="date"
    ></year-month-number-input>
  </input-field>
</template>

<script>
import { NumberKeyboard } from "vant";
import InputField from "./InputField";
import YearMonthNumberInput from "@/components/YearMonthNumberInput";

export default {
  props: {
    name: {
      type: String,
    },
    value: {
      type: String,
    },
    tip: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    InputField,
    YearMonthNumberInput,
    [NumberKeyboard.name]: NumberKeyboard,
  },
  data() {
    return {
      date: this.value,
    };
  },
  watch: {
    date() {
      console.log('YearMonthInputField', this.date)
      this.$emit("input", this.date);
    },
  },
};
</script>

<style scoped>
.field-box >>> .van-divider {
  margin: 0;
}

.field {
  display: flex;
  align-items: center;
  height: 60px;
}

.name {
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #333333;
  display: flex;
  align-items: center;
}

.tip {
  width: 14px;
  height: 14px;
}

.data {
  flex: 1;
  text-align: right;
  font-size: 24px;
  font-weight: bold;
  line-height: 33px;
  color: #1a1a1a;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.unit {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #999999;
}
</style>